<template>
	<div class="menu list-menu">
		<div class="row mt-3">
			<div class="col">
				<h3 class="mb-2">Actions</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="add">Add Action</button>
			</div>
		</div>
		<div class="card card-body p-1 my-2" v-for="(obj, key) in items" :key="obj.id" v-on:click="update(key)">
			<div class="row">
				<div class="col-4">
					<h4>{{ key }}</h4>
					<h6>{{ obj.id }}</h6>
				</div>
				<div class="col-4">
					<h4>{{ obj.action_type }}</h4>
				</div>
				<div class="col-4">
					<h4>{{ obj.on_success ? obj.on_success : '-' }}</h4>
					<h4>{{ obj.on_error ? obj.on_error : '-' }}</h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ActionListMenu",
	props: {
		manifest: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			action_types: [
				{
					"id": "1x0",
					"action_type": "smart_contract_call",
					"data": {
						"contract_addr": "",
						"method": "",
					}
				}, {
					"id": "2x0",
					"action_type": "external_call",
					"data": {
						"target": "",
						"method": "",
					}
				},
				{
					"id": "3x0",
					"action_type": "api_call",
					"data": {
						"method": "GET",
						"url": "",
						"headers": {
							"Content-Type": "application/json",
							"Authorization": "$token"
						},
						"body": {
							"data": "$1"
						},
					}
				},
				{
					"id": "4x0",
					"action_type": "custom_code",
					"data": {
						"code": "", //Base64
					}
				},
				{
					"id": "5x0",
					"action_type": "show_success",
					"data": {
						"text": "Success: $1",
					}
				},
				{
					"id": "6x0",
					"action_type": "show_error",
					"data": {
						"text": "Error: $1",
					}
				},
			],
			defaults: {},
			show_custom_only: false,
		}
	},
	computed: {
		items: function () {
			if (this.show_custom_only)
				return this.manifest.actions;

			return {
				...this.defaults,
				...this.manifest.actions,
			};
		}
	},
	methods: {
		update(id) {
			this.$emit("update", id);
		},
		add(e) {
			e.preventDefault();
			this.$emit("add", {action_type: "api_call", data: {}});
		}
	}
}
</script>

<style scoped>
.card {
	transition: all 0.2s ease-out;
}

h4 {
	font-size: 1em;
}

h6 {
	font-size: 0.6em;
	color: grey;
}

.card:hover {
	background: rgba(0, 0, 0, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}
</style>