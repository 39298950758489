<template>
	<div class="menu npc-menu">
		<div class="row mt-3">
			<div class="col">
				<h4 class="mb-2">NPC</h4>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="deselect">Back</button>
			</div>
		</div>


		<div class="col-12" v-if="$store.state.metaverse.manifest.models[form.character_id]">
			<model-viewer class="viewer" auto-rotate style="width: 100%" :src="$store.state.metaverse.manifest.models[form.character_id]"></model-viewer>
		</div>

		<div class="form-group">
			<label>ID</label>
			<input :value="form.id" disabled class="form-control">
		</div>

		<div class="form-group">
			<label>Name</label>
			<input v-model="form.name" class="form-control">
		</div>

		<div class="form-group">
			<label>Character</label>
			<select class="form-control" v-model="form.character_id">
				<option v-for="(character,key) in $store.state.metaverse.manifest.characters" :value="character.model" :key="key">{{ character.name }}</option>
			</select>
		</div>

		<div class="form-group">
			<label>Health</label>
			<input v-model.number="form.health" type="number" class="form-control">
		</div>

		<div class="form-group">
			<label>Level</label>
			<input v-model.number="form.level" type="number" class="form-control">
		</div>

		<div class="form-group">
			<label>Move Speed</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" step="0.5" v-model.number="form.move_speed">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.move_speed" step="1" min="0">
				</div>
			</div>
		</div>
        
        <hr>

		<div class="form-group mb-3">
			<label>Spawn Position</label>
			<Vector3 v-model="form.spawn_position"></Vector3>
		</div>

		<div class="form-group mb-3">
			<label>Rotation</label>
			<Vector3 v-model="form.rotation"></Vector3>
		</div>

		<div class="form-group mb-3">
			<label>Scale</label>
			<Vector3 v-model="form.scale"></Vector3>
		</div>

        <hr>

        <ActionMenu
            class="interaction click card p-3 m-3"
            interaction-type="click"
            :interaction="form.interactions['click']"
            @update="updateInteractions"
        />

        <ActionMenu
            class="interaction proximity card p-3 m-3"
            interaction-type="proximity"
            :interaction="form.interactions['proximity']"
            @update="updateInteractions"
        >
            <div class="form-group">
                <label>Activation Radius</label>
                <input
                    v-model.number="form.interactions['proximity'].activation_radius"
                    class="form-control"
                    type="number"
                />
            </div>
        </ActionMenu>

        <hr>

		<div class="text-center">
			<button class="btn btn-primary mt-3 btn-wide" @click="update">Update</button>
			<button @click="deleteItem" class="btn btn-danger mt-3 btn-wide">Delete</button>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import ActionMenu from "../menus/ActionMenu.vue"
import Vector3 from "../fields/Vector3";

export default Vue.extend({
	name: "NPCMenu",
	components: {
        ActionMenu,
		Vector3,
	},
	props: {
		meta: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			property: {
				property: 'npc',
				object: null,
			},
			form: {
				id: '',
				name: '',
				character_id: '',
				health: -1,
				level: -1,
				move_speed: 10,
				spawn_position: [0,0,0],
				rotation: [0,0,0],
				scale: [0,0,0],
				animations: {
					idle: 'idle',
					move: 'run',
				},
			},
		}
	},
    watch: {
		item: {
			handler(newValue) {
				this.form = newValue
				this.$forceUpdate()
				// this.$set(this, 'form', newValue)
			},
			deep: true,
		},
    },
	computed: {
		item() {
			return this.$store.state.metaverse.manifest.npcs.filter(d => {
				return d.id === this.meta
			})[0]
		}
	},
	methods: {
		...mapActions({
			manifestUpdate: 'metaverse/manifestUpdate',
			manifestDelete: 'metaverse/manifestDelete',
		}),

		updateInteractions(interaction) {
			this.form.interactions = {
				...this.form.interactions,
				...interaction,
			}
		},

		deselect() {
			this.$emit('select', {menu: 'npcs', item_id: null})
		},

		update() {
			this.manifestUpdate({
				...this.property,
				object: this.form,
			})

			this.$emit("update", this.form);
		},

		deleteItem() {
			this.manifestDelete({
				...this.property,
				object: this.form,
			})

			this.$emit('delete', this.form.id)
			this.deselect()
		},
	},
	beforeMount() {
		// this.form = Object.assign({}, this.item);
		console.log("Form1", this.form);
		this.$set(this, 'form', this.item)
		// this.form = {
		// 	...this.form,
		// 	...this.item,
		// }
		// this.form.id = this.item.id
		// this.form.name = this.item.name
		// this.form.character_id = this.item.character_id
		// this.form.spawn_position = this.item.spawn_position
		// this.form.rotation = this.item.rotation
		// this.form.scale = this.item.scale
		console.log("Form2", this.form);

	}
})
</script>

<style scoped>
.menu > .form-group {
	border: unset;
	background: unset;
	text-align: left;
	padding: 0;
	font-size: 0.8em;
}

.menu > .form-group > .form-control {
	font-size: 0.8em;
	background: unset;
	color: white;
	text-align: left;
}

.menu > .form-group > select option {
	background: black;
	color: white;
}
</style>
