var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "menu terrain-list-menu mt-4 mb-3" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("h1", { staticClass: "mt-3" }, [_vm._v("Uploaded")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _vm._l(_vm.items, function (model, key) {
          return _c(
            "div",
            {
              key: model.id,
              staticClass: "col-4",
              on: {
                click: function ($event) {
                  return _vm.add({ sprite_uri: model, name: key })
                },
              },
            },
            [_c("CatalogueListItem", { attrs: { item: model, name: key } })],
            1
          )
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _c("div", { staticClass: "card h-100" }, [
            _c(
              "div",
              {
                staticClass: "card-body model-upload pt-5",
                on: { click: _vm.goToUpload },
              },
              [
                _c("i", { staticClass: "fa fa-upload" }),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-3" }, [_vm._v("Upload")]),
              ]
            ),
          ]),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    _vm.$store.state.wallet.isWeb3
      ? _c("div", [
          _c("h1", { staticClass: "mt-5" }, [_vm._v("NFT's")]),
          _vm._v(" "),
          _vm.ua !== null
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.parsedNFTs.length === 0
                    ? _c(
                        "div",
                        { staticClass: "card card-body text-center no-items" },
                        [
                          _c("h2", { staticClass: "mt-2" }, [
                            _vm._v("No Items Detected"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "my-4 btn btn-primary",
                              attrs: { to: "/login" },
                            },
                            [_vm._v("Login")]
                          ),
                          _vm._v(" "),
                          _vm.$store.state.wallet.connected
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-secondary m-4",
                                  attrs: { disabled: true },
                                },
                                [
                                  _vm._v(
                                    "Go To\n\t\t\t\t\tMarketplace\n\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.parsedNFTs, function (model, key) {
                    return _vm.ua !== null
                      ? _c(
                          "div",
                          {
                            key: model.id,
                            staticClass: "col-4",
                            on: {
                              click: function ($event) {
                                return _vm.addNFT(model)
                              },
                            },
                          },
                          [
                            _c("CatalogueListItem", {
                              attrs: {
                                item: model.sprite_uri,
                                poster: model.poster_uri,
                                name: model.name,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("h1", { staticClass: "mt-5" }, [_vm._v("Free")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-12" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "form-control",
            attrs: { placeholder: "Search" },
            domProps: { value: _vm.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm.ab_items.length === 0
          ? _c("div", { staticClass: "card card-body text-center no-items" }, [
              _c("h2", [_vm._v("No items")]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.filteredPrefabs, function (model) {
          return _c(
            "div",
            {
              key: model.id,
              class: `${_vm.isSidebar ? "col-4" : "col-4 col-lg-2"}`,
              on: {
                click: function ($event) {
                  return _vm.add(model)
                },
              },
            },
            [
              _c("CatalogueListItem", {
                attrs: {
                  item: model.sprite_uri,
                  poster: model.poster_uri,
                  name: model.name,
                },
              }),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h3", { staticClass: "mb-2" }, [_vm._v("Items")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }