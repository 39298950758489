var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid editor-menu mb-3" }, [
    _c(
      "form",
      [
        _vm.isActive("items")
          ? _c("CatalogueListMenu", {
              attrs: { "is-sidebar": _vm.isSidebar },
              on: {
                select: (options) => _vm.$emit("select", options),
                add: (e) => _vm.onAdd("models", e),
                upload: (e) => _vm.onAdd("models", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("terrain")
          ? _c("TerrainListMenu", {
              on: {
                select: (options) => _vm.$emit("select", options),
                add: (e) => _vm.onAdd("terrain", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveItem("terrain")
          ? _c("TerrainMenu", {
              attrs: { meta: _vm.activeMenu.item_id },
              on: {
                add: (e) => _vm.onAdd("terrain", e),
                select: (options) => _vm.$emit("select", options),
                update: (e) => _vm.onUpdate("terrain", e),
                delete: (e) => _vm.onDelete("terrain", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("entities")
          ? _c("EntityListMenu", {
              on: {
                select: (options) => _vm.$emit("select", options),
                add: (e) => _vm.onAdd("entity", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveItem("entities")
          ? _c("EntityMenu", {
              attrs: { meta: _vm.activeMenu.item_id },
              on: {
                add: (e) => _vm.onAdd("entities", e),
                select: (options) => _vm.$emit("select", options),
                update: (e) => _vm.onUpdate("entities", e),
                delete: (e) => _vm.onDelete("entities", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("environment")
          ? _c("EnvironmentMenu", {
              on: { update: (e) => _vm.onUpdate("environment", e) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("lighting")
          ? _c("LightListMenu", {
              on: {
                select: (options) => _vm.$emit("select", options),
                add: (e) => _vm.onAdd("lighting", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveItem("lighting")
          ? _c("LightingMenu", {
              attrs: { meta: _vm.activeMenu.item_id },
              on: {
                select: (options) => _vm.$emit("select", options),
                update: (e) => _vm.onUpdate("lighting", e),
                delete: (e) => _vm.onDelete("lighting", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("sound")
          ? _c("SoundListMenu", {
              on: {
                update: (e) => _vm.onUpdate("sound_list", e),
                add: (e) => _vm.onAdd("sound", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveItem("sound")
          ? _c("SoundMenu", {
              attrs: { meta: _vm.activeMenu.item_id },
              on: {
                update: (e) => _vm.onUpdate("sound", e),
                delete: (e) => _vm.onDelete("sound", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("settings")
          ? _c("MetaverseSettingsMenu", {
              on: { update: (e) => _vm.onUpdate("settings", e) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("models")
          ? _c("ModelListMenu", {
              on: {
                select: (options) => _vm.$emit("select", options),
                add: (e) => _vm.onAdd("models", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveItem("models")
          ? _c("ModelMenu", {
              attrs: { meta: _vm.activeMenu.item_id },
              on: {
                select: (options) => _vm.$emit("select", options),
                update: (e) => _vm.onUpdate("models", e),
                delete: (e) => _vm.onDelete("models", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("npcs")
          ? _c("NPCListMenu", {
              on: {
                select: (options) => _vm.$emit("select", options),
                add: (e) => _vm.onAdd("npcs", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveItem("npcs")
          ? _c("NPCMenu", {
              attrs: { meta: _vm.activeMenu.item_id },
              on: {
                select: (options) => _vm.$emit("select", options),
                update: (e) => _vm.onUpdate("npcs", e),
                delete: (e) => _vm.onDelete("npcs", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("characters")
          ? _c("CharacterListMenu", {
              on: {
                select: (options) => _vm.$emit("select", options),
                add: (e) => _vm.onAdd("characters", e),
                "add-default": (e) => _vm.onAddDefault(e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveItem("characters")
          ? _c("CharacterMenu", {
              attrs: { meta: _vm.activeMenu.item_id },
              on: {
                select: (options) => _vm.$emit("select", options),
                update: (e) => _vm.onUpdate("characters", e),
                delete: (e) => _vm.onDelete("characters", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveItem("file")
          ? _c("FileMenu", {
              attrs: { meta: _vm.activeMenu.item_id, manifest: _vm.manifest },
              on: {
                update: (e) => _vm.onUpdate("file", e),
                delete: (e) => _vm.onDelete("file", e),
                add: (e) => _vm.onAdd("file", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("action_list")
          ? _c("ActionListMenu", {
              attrs: { manifest: _vm.manifest },
              on: {
                update: (e) => _vm.onUpdate("action_list", e),
                add: (e) => _vm.onAdd("action", e),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveItem("action")
          ? _c("ActionMenu", {
              attrs: { meta: _vm.activeMenu.item_id },
              on: { update: (e) => _vm.onUpdate("actions", e) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("display")
          ? _c("PostProcessingMenu", {
              attrs: { manifest: _vm.manifest },
              on: { update: (e) => _vm.onUpdate("display", e) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive("voxels")
          ? _c("VoxelsMenu", {
              on: {
                select: _vm.selectVoxel,
                uploaded_texture: (fileUrl) =>
                  _vm.$emit("uploaded_texture", fileUrl),
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }