<template>
	<div class="menu list-menu">
		<div class="row mt-3">
			<div class="col">
				<h4 class="mb-2">NPCs</h4>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="add">Add NPC</button>
			</div>
		</div>

		<div class="card card-body text-center mt-4 no-items" v-if="items.length === 0">
			<h2 class="my-4">No items</h2>
			<button class="btn btn-outline-primary btn-sm btn-sm-rel" @click="add">Add NPC</button>
		</div>

		<div class="card card-body p-1 my-2" v-for="obj in items" :key="obj.id"
				@click="select(obj.id)">
			<div class="row">
				<div class="col text-center">
					<h4>{{ obj.id }}</h4>
				</div>
				<div class="col">
					<h4>{{ obj.name }}</h4>
					<h6>{{ obj.level }}</h6>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapActions} from "vuex"

import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";

export default Vue.extend({
	name: "NPCListMenu",
	data() {
		return {
			property: {
				property: 'npc',
				object: null,
			},
		}
	},
	computed: {
		items: function () {
			return this.$store.state.metaverse.manifest.npcs || []
		}
	},
	methods: {
		...mapActions({
			manifestAdd: 'metaverse/manifestAdd',
		}),

		select(id) {
			this.$emit('select', {menu: 'npcs', item_id: id})
		},

		add(e) {
			e.preventDefault()
			const uuid = uuidv4()

			const newNPC = {
				id: uuid,
				name: uuid,
				character_id: null,
				health: -1,
				level: -1,
				walk_speed: 10,
				run_speed: 13,
				spawn_position: [0, 0, 0],
				rotation: [0, 0, 0],
				scale: [0.1, 0.1, 0.1],
				idle_animation: 'idle',
				run_animation: 'run',
				walk_animation: 'walk',
				interactions: {
					click: {},
					proximity: {},
				},
			}

			this.manifestAdd({
				...this.property,
				object: newNPC,
			})

			this.$emit("add", newNPC)
			this.select(newNPC.id)
		},
	}
})
</script>

<style scoped>
.card {
	transition: all 0.2s ease-out;
}

h4 {
	font-size: 0.8em;
}

h6 {
	font-size: 0.6em;
	color: grey;
}

.card:hover {
	background: rgba(0, 0, 0, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}
</style>
