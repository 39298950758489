<template>
	<div class="menu display-menu my-3">
		<h3 class="mb-2 text-center">Display</h3>


		<h6 class="mb-0 text-start mt-3">Bloom</h6>
		<div class="form-group">
			<label>Threshold</label>

			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" step="0.1" v-model.number="form.bloom.threshold">
				</div>
				<div class="col">
					<input type="range" v-model.number="form.bloom.threshold" class="range-slider mt-4" min="0" step="0.1" max="3">
				</div>
			</div>

		</div>

		<div class="form-group">
			<label>Intensity</label>

			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" step="0.1" v-model.number="form.bloom.intensity">
				</div>
				<div class="col">
					<input type="range" v-model.number="form.bloom.intensity" class="range-slider mt-4" min="0" step="0.1" max="2">
				</div>
			</div>
		</div>
		<div class="form-group">
			<label>Radius</label>

			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" max="2" step="0.1" v-model.number="form.bloom.radius">
				</div>
				<div class="col">
					<input type="range" v-model.number="form.bloom.radius" class="range-slider mt-4" min="0" max="2" step="0.1">
				</div>
			</div>
		</div>
		<div class="form-group">
			<label>Exposure</label>

			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" step="0.1" max="5" v-model.number="form.exposure">
				</div>
				<div class="col">
					<input type="range" v-model.number="form.exposure" class="range-slider mt-4" min="0" step="0.1" max="5">
				</div>
			</div>
		</div>

		<div class="text-center">
			<hr>
			<button class="btn btn-primary mt-3 btn-wide" @click="update">Update</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "PostProcessingMenu",
	props: {
		manifest: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			form: {
				bloom: {
					threshold: 2,
					intensity: 0.1,
					radius: 0,
				},
				exposure: 1,
			},
		}
	},
	// computed: {
	// 	item() {
	// 		return this.manifest.display;
	// 	}
	// },
	methods: {
		update(e) {
			e.preventDefault()
			this.$emit("update", this.form);
		}
	},
	mounted() {
		this.form = this.manifest.display || this.form
	}
}
</script>

<style scoped>
</style>