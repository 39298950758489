var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu display-menu my-3" }, [
    _c("h3", { staticClass: "mb-2 text-center" }, [_vm._v("Display")]),
    _vm._v(" "),
    _c("h6", { staticClass: "mb-0 text-start mt-3" }, [_vm._v("Bloom")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Threshold")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.bloom.threshold,
                expression: "form.bloom.threshold",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", step: "0.1" },
            domProps: { value: _vm.form.bloom.threshold },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.form.bloom,
                  "threshold",
                  _vm._n($event.target.value)
                )
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.bloom.threshold,
                expression: "form.bloom.threshold",
                modifiers: { number: true },
              },
            ],
            staticClass: "range-slider mt-4",
            attrs: { type: "range", min: "0", step: "0.1", max: "3" },
            domProps: { value: _vm.form.bloom.threshold },
            on: {
              __r: function ($event) {
                _vm.$set(
                  _vm.form.bloom,
                  "threshold",
                  _vm._n($event.target.value)
                )
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Intensity")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.bloom.intensity,
                expression: "form.bloom.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", step: "0.1" },
            domProps: { value: _vm.form.bloom.intensity },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.form.bloom,
                  "intensity",
                  _vm._n($event.target.value)
                )
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.bloom.intensity,
                expression: "form.bloom.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "range-slider mt-4",
            attrs: { type: "range", min: "0", step: "0.1", max: "2" },
            domProps: { value: _vm.form.bloom.intensity },
            on: {
              __r: function ($event) {
                _vm.$set(
                  _vm.form.bloom,
                  "intensity",
                  _vm._n($event.target.value)
                )
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Radius")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.bloom.radius,
                expression: "form.bloom.radius",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", max: "2", step: "0.1" },
            domProps: { value: _vm.form.bloom.radius },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form.bloom, "radius", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.bloom.radius,
                expression: "form.bloom.radius",
                modifiers: { number: true },
              },
            ],
            staticClass: "range-slider mt-4",
            attrs: { type: "range", min: "0", max: "2", step: "0.1" },
            domProps: { value: _vm.form.bloom.radius },
            on: {
              __r: function ($event) {
                _vm.$set(_vm.form.bloom, "radius", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Exposure")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.exposure,
                expression: "form.exposure",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", step: "0.1", max: "5" },
            domProps: { value: _vm.form.exposure },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "exposure", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.exposure,
                expression: "form.exposure",
                modifiers: { number: true },
              },
            ],
            staticClass: "range-slider mt-4",
            attrs: { type: "range", min: "0", step: "0.1", max: "5" },
            domProps: { value: _vm.form.exposure },
            on: {
              __r: function ($event) {
                _vm.$set(_vm.form, "exposure", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "text-center" }, [
      _c("hr"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary mt-3 btn-wide",
          on: { click: _vm.update },
        },
        [_vm._v("Update")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }