var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu list-menu" },
    [
      _c("div", { staticClass: "row mt-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm float-end",
              on: { click: _vm.add },
            },
            [_vm._v("Add Action")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.items, function (obj, key) {
        return _c(
          "div",
          {
            key: obj.id,
            staticClass: "card card-body p-1 my-2",
            on: {
              click: function ($event) {
                return _vm.update(key)
              },
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-4" }, [
                _c("h4", [_vm._v(_vm._s(key))]),
                _vm._v(" "),
                _c("h6", [_vm._v(_vm._s(obj.id))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("h4", [_vm._v(_vm._s(obj.action_type))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("h4", [
                  _vm._v(_vm._s(obj.on_success ? obj.on_success : "-")),
                ]),
                _vm._v(" "),
                _c("h4", [_vm._v(_vm._s(obj.on_error ? obj.on_error : "-"))]),
              ]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Actions")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }