<template>
	<div class="menu terrain-list-menu mt-4 mb-3">
		<div class="row">
			<div class="col">
				<h3 class="mb-2">Items</h3>
			</div>
		</div>

		<h1 class="mt-3">Uploaded</h1>
		<div class="row">
			<div class="col-4" v-for="(model,key) in items" :key="model.id"
					 @click="add({sprite_uri: model, name: key})">
				<CatalogueListItem :item="model" :name="key"></CatalogueListItem>
			</div>

			<div class="col-4">
				<div class="card h-100">
					<div class="card-body model-upload pt-5" @click="goToUpload">
						<i class="fa fa-upload"></i>
						<h4 class="mt-3">Upload</h4>
					</div>
				</div>
			</div>
		</div>

		<div v-if="$store.state.wallet.isWeb3">
			<h1 class="mt-5">NFT's</h1>
			<div class="row" v-if="ua !== null">
				<div class="card card-body text-center no-items" v-if="parsedNFTs.length === 0">
					<h2 class="mt-2">No Items Detected</h2>

					<router-link to="/login" class="my-4 btn btn-primary">Login</router-link>
					<!--				<MultiWallet class="my-4"></MultiWallet>-->
					<!--				<Phantom class="btn btn-primary my-4" v-if="!$store.state.wallet.connected"></Phantom>-->
					<button class="btn btn-outline-secondary m-4" :disabled="true" v-if="$store.state.wallet.connected">Go To
						Marketplace
					</button>
				</div>

				<div v-if="ua !== null" class="col-4" v-for="(model,key) in parsedNFTs" :key="model.id"
						@click="addNFT(model)">
					<CatalogueListItem :item="model.sprite_uri" :poster="model.poster_uri" :name="model.name"></CatalogueListItem>
				</div>
			</div>
		</div>

		<h1 class="mt-5">Free</h1>
		<div class="row">
			<div class="col-12">
				<input class="form-control" placeholder="Search" v-model="search">
			</div>

			<div class="card card-body text-center no-items" v-if="ab_items.length === 0">
				<h2>No items</h2>
			</div>
			<div :class="`${isSidebar ? 'col-4' : 'col-4 col-lg-2'}`" v-for="model in filteredPrefabs" :key="model.id"
					 @click="add(model)">
				<CatalogueListItem :item="model.sprite_uri" :poster="model.poster_uri" :name="model.name"></CatalogueListItem>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import axios from "axios";
import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";

import CatalogueListItem from "./CatalogueListItem";
import {UserAssets} from "../../../js/util/user_assets";
import Phantom from "../../wallet/Phantom";
import {isModel} from "../../../js/util/modelDetector";
import MultiWallet from "../../wallet/MultiWallet";

export default Vue.extend({
	name: "CatalogueListMenu",
	components: {MultiWallet, Phantom, CatalogueListItem},
	props: {
		isSidebar: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			property: {
				property: 'terrain',
				object: null,
			},
			search: '',
			ab_items: [],
			ua: null,
		}
	},
	computed: {
		items: function () {
			return this.$store.state.metaverse.manifest.models
		},
		nft_items: function () {
			return [];
		},
		filteredPrefabs: function () {
			if (this.search !== '') {
				const lc = this.search.toLowerCase()
				return this.ab_items.filter((s) => s.name.toLowerCase().indexOf(lc) > -1)
			}

			return this.ab_items
		},
		parsedNFTs: function () {
			const nfts = [];

			for (let i = 0; i < this.ua.ownedNFT.length; i++) {
				const _n = this.ua.ownedNFT[i];
				const nft = {
					id: _n.id,
					name: _n.name,
					sprite_uri: _n.metadata.animation_url ? _n.metadata.animation_url : _n.image,
					poster_uri: _n.image,
					files: _n.metadata.properties?.files
				};

				if (_n.nftAddress) {
					nft.nftAddress = _n.nftAddress;
				}

				// console.log("Parsing nft", _n ,nft)
				nfts.push(nft);
			}

			return nfts;
		}
	},
	watch: {
		'$store.state.wallet.address'() {
			if (this.ua)
				this.ua.getAccountInfo(this.$store.state.wallet.address)
		}
	},
	methods: {
		...mapActions({
			manifestAdd: 'metaverse/manifestAdd',
		}),

		select(id) {
			this.$emit('select', {menu: 'terrain', item_id: id})
		},

		addNFT(model) {
			console.log("Adding NFT", model);
			const interactions = {
				"click": {
					action_type: "show_nft_single",
					data: {
						token_addr: model.id,
						mint_addr: "",
						chain_id: 0,
					},
					on_error: null,
					on_success: null
				}
			}

			this.add({sprite_uri: isModel(model.sprite_uri) ? model.sprite_uri : model.poster_uri, name: model.name, nftAddress: model.id, interactions: interactions})
		},

		add(model) {
			const modelInfo = {
				id: uuidv4(),
				name: model.name,
				scene: model.sprite_uri,
				position: {x: 0, y: 0, z: 0}, //TODO add in front of user?
				rotation: {x: 0, y: 0, z: 0},
				scale: {x: 1, y: 1, z: 1},
			}

			if (model.interactions)
				modelInfo.interactions = model.interactions

			if (model.details) {
				modelInfo.scale = {
					x: model.details.scale_x,
					y: model.details.scale_y,
					z: model.details.scale_z,
				}
			}

			this.manifestAdd({
				...this.property,
				object: modelInfo
			})

			this.$emit("add", modelInfo);
			this.select(modelInfo.id)
		},

		alphaItems() {
			axios.get("https://app.alphabatem.com/prefabs?limit=200").then((resp) => {
				console.log("ab items", resp.data)
				this.ab_items = resp.data.filter(item => item.display_type === 1);
			})
		},

		goToUpload(e) {
			e.preventDefault();
			// this.$emit("upload", {
			// 	name: "NEW",
			// 	scene: "",
			// })
			this.$emit('select', {menu: 'models'})
		}
	},
	mounted() {
		this.alphaItems();
		this.ua =  this.$store.state.profile.userAssets;

		if (this.$store.state.wallet.connected) {
			this.ua.getAccountInfo(this.$store.state.wallet.address)
		}
	}
})
</script>

<style scoped>
.terrain {
	transition: all 0.2s ease-out;
}

.model-upload {
	cursor: pointer;
	border: 2px dashed white;
	border-radius: 7px;
}

h1 {
	text-align: left;
	text-transform: uppercase;
	font-size: 1.5em;
}

h4 {
	font-size: 1em;
}

h6 {
	font-size: 0.6em;
	color: grey;
}

.terrain:hover {
	background: rgba(0, 0, 0, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}

.search {
	background: unset;
}
</style>
