var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "voxels-menu mt-4 mb-3" }, [
    _c("div", [
      _c(
        "div",
        {
          staticClass: "collapser",
          on: {
            click: function ($event) {
              _vm.expanded = !_vm.expanded
            },
          },
        },
        [_c("p", [_vm._v("-")])]
      ),
      _vm._v(" "),
      _c(
        "h4",
        {
          staticClass: "mb-3",
          on: {
            click: function ($event) {
              _vm.expanded = !_vm.expanded
            },
          },
        },
        [_vm._v("Custom Voxels")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expanded,
              expression: "expanded",
            },
          ],
        },
        [
          _vm.$store.state.metaverse.manifest.textures.length === 0
            ? _c("div", [_c("span", [_vm._v("No custom voxels uploaded.")])])
            : _c(
                "div",
                { staticClass: "custom-voxels-area" },
                _vm._l(
                  _vm.$store.state.metaverse.manifest.textures,
                  function (voxel, index) {
                    return _c(
                      "div",
                      {
                        key: `custom-voxel-${index}`,
                        staticClass: "voxel_selection",
                        style: {
                          "border-color":
                            parseInt(index) === _vm.selectedVoxel
                              ? "green"
                              : "darkgrey",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.onVoxelClick(parseInt(index))
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "40px", height: "40px" },
                          attrs: { src: voxel, alt: "alt" },
                        }),
                      ]
                    )
                  }
                ),
                0
              ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("ImageUploader", {
            staticClass: "pb-3 mt-3",
            attrs: { endpoint: "/auth/users/files" },
            on: { uploaded: _vm.handleUploadedFile },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: {
          color: "black",
          position: "fixed",
          bottom: "0",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
        },
      },
      _vm._l(_vm.voxels, function (voxel) {
        return _c(
          "div",
          {
            key: voxel.name,
            staticClass: "voxel_selection",
            style: {
              "border-color":
                voxel.type === _vm.selectedVoxel ? "green" : "darkgrey",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onVoxelClick(voxel.type)
              },
            },
          },
          [
            _c("img", {
              staticStyle: { width: "40px", height: "40px" },
              attrs: { src: voxel.src, alt: "alt" },
            }),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticClass: "target",
        attrs: { src: "/assets/voxel_builder/voxels_target.svg" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }