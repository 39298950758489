<template>
	<div class="menu file-menu"></div>
</template>

<script>
export default {
	name: "FileMenu",
	data() {
		return {
			data: {}
		}
	},
	methods: {
		update(e) {
			e.preventDefault()
			this.$emit("update", data);
		}
	}
}
</script>

<style scoped>

</style>