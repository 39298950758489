var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu npc-menu" },
    [
      _c("div", { staticClass: "row mt-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm float-end",
              on: { click: _vm.deselect },
            },
            [_vm._v("Back")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.$store.state.metaverse.manifest.models[_vm.form.character_id]
        ? _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("model-viewer", {
                staticClass: "viewer",
                staticStyle: { width: "100%" },
                attrs: {
                  "auto-rotate": "",
                  src: _vm.$store.state.metaverse.manifest.models[
                    _vm.form.character_id
                  ],
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("ID")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { disabled: "" },
          domProps: { value: _vm.form.id },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Name")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.name,
              expression: "form.name",
            },
          ],
          staticClass: "form-control",
          domProps: { value: _vm.form.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "name", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Character")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.character_id,
                expression: "form.character_id",
              },
            ],
            staticClass: "form-control",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.form,
                  "character_id",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          _vm._l(
            _vm.$store.state.metaverse.manifest.characters,
            function (character, key) {
              return _c(
                "option",
                { key: key, domProps: { value: character.model } },
                [_vm._v(_vm._s(character.name))]
              )
            }
          ),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Health")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.form.health,
              expression: "form.health",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number" },
          domProps: { value: _vm.form.health },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "health", _vm._n($event.target.value))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Level")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.form.level,
              expression: "form.level",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number" },
          domProps: { value: _vm.form.level },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "level", _vm._n($event.target.value))
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Move Speed")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.form.move_speed,
                  expression: "form.move_speed",
                  modifiers: { number: true },
                },
              ],
              staticClass: "form-control menu-control",
              attrs: { type: "number", step: "0.5" },
              domProps: { value: _vm.form.move_speed },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "move_speed", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.form.move_speed,
                  expression: "form.move_speed",
                  modifiers: { number: true },
                },
              ],
              staticClass: "btn-block mt-2",
              attrs: { type: "range", step: "1", min: "0" },
              domProps: { value: _vm.form.move_speed },
              on: {
                __r: function ($event) {
                  _vm.$set(_vm.form, "move_speed", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group mb-3" },
        [
          _c("label", [_vm._v("Spawn Position")]),
          _vm._v(" "),
          _c("Vector3", {
            model: {
              value: _vm.form.spawn_position,
              callback: function ($$v) {
                _vm.$set(_vm.form, "spawn_position", $$v)
              },
              expression: "form.spawn_position",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group mb-3" },
        [
          _c("label", [_vm._v("Rotation")]),
          _vm._v(" "),
          _c("Vector3", {
            model: {
              value: _vm.form.rotation,
              callback: function ($$v) {
                _vm.$set(_vm.form, "rotation", $$v)
              },
              expression: "form.rotation",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group mb-3" },
        [
          _c("label", [_vm._v("Scale")]),
          _vm._v(" "),
          _c("Vector3", {
            model: {
              value: _vm.form.scale,
              callback: function ($$v) {
                _vm.$set(_vm.form, "scale", $$v)
              },
              expression: "form.scale",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("ActionMenu", {
        staticClass: "interaction click card p-3 m-3",
        attrs: {
          "interaction-type": "click",
          interaction: _vm.form.interactions["click"],
        },
        on: { update: _vm.updateInteractions },
      }),
      _vm._v(" "),
      _c(
        "ActionMenu",
        {
          staticClass: "interaction proximity card p-3 m-3",
          attrs: {
            "interaction-type": "proximity",
            interaction: _vm.form.interactions["proximity"],
          },
          on: { update: _vm.updateInteractions },
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Activation Radius")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.form.interactions["proximity"].activation_radius,
                  expression:
                    "form.interactions['proximity'].activation_radius",
                  modifiers: { number: true },
                },
              ],
              staticClass: "form-control",
              attrs: { type: "number" },
              domProps: {
                value: _vm.form.interactions["proximity"].activation_radius,
              },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.form.interactions["proximity"],
                    "activation_radius",
                    _vm._n($event.target.value)
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary mt-3 btn-wide",
            on: { click: _vm.update },
          },
          [_vm._v("Update")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-danger mt-3 btn-wide",
            on: { click: _vm.deleteItem },
          },
          [_vm._v("Delete")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h4", { staticClass: "mb-2" }, [_vm._v("NPC")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }