<template>
	<div class="container-fluid editor-menu mb-3">
		<form>
			<!--			Catalogue-->
			<CatalogueListMenu
				v-if="isActive('items')"
				:is-sidebar="isSidebar"
				@select="(options) => $emit('select', options)"
				@add="(e) => onAdd('models', e)"
				@upload="(e) => onAdd('models', e)"
			></CatalogueListMenu>

			<TerrainListMenu
					v-if="isActive('terrain')"
					@select="(options) => $emit('select', options)"
					@add="(e) => onAdd('terrain',e)"
			></TerrainListMenu>
			<TerrainMenu
					v-if="isActiveItem('terrain')"
					:meta="activeMenu.item_id"
					@add="(e) => onAdd('terrain',e)"
					@select="(options) => $emit('select', options)"
					@update="(e) => onUpdate('terrain',e)"
					@delete="(e)=> onDelete('terrain',e)"
			></TerrainMenu>

			<EntityListMenu
					v-if="isActive('entities')"
					@select="(options) => $emit('select', options)"
					@add="(e) => onAdd('entity', e)"
			></EntityListMenu>

			<EntityMenu
					v-if="isActiveItem('entities')"
					:meta="activeMenu.item_id"
					@add="(e) => onAdd('entities',e)"
					@select="(options) => $emit('select', options)"
					@update="(e) => onUpdate('entities', e)"
					@delete="(e)=> onDelete('entities', e)"
			></EntityMenu>

			<EnvironmentMenu
					v-if="isActive('environment')"
					@update="(e) => onUpdate('environment', e)"
			></EnvironmentMenu>

			<!--		Lighting-->
			<LightListMenu
					v-if="isActive('lighting')"
					@select="(options) => $emit('select', options)"
					@add="(e) => onAdd('lighting',e)"
			></LightListMenu>
			<LightingMenu
					v-if="isActiveItem('lighting')"
					:meta="activeMenu.item_id"
					@select="(options) => $emit('select', options)"
					@update="(e) => onUpdate('lighting',e)"
					@delete="(e)=> onDelete('lighting',e)"
			></LightingMenu>

			<!--			Sound-->
			<SoundListMenu
					v-if="isActive('sound')"
					@update="(e) => onUpdate('sound_list',e)"
					@add="(e) => onAdd('sound',e)"
			></SoundListMenu>
			<SoundMenu
					v-if="isActiveItem('sound')"
					:meta="activeMenu.item_id"
					@update="(e) => onUpdate('sound',e)"
					@delete="(e)=> onDelete('sound',e)"
			></SoundMenu>

			<!--		Settings-->
			<MetaverseSettingsMenu
					v-if="isActive('settings')"
					@update="(e) => onUpdate('settings',e)"
			></MetaverseSettingsMenu>

			<!--			Models-->
			<ModelListMenu
					v-if="isActive('models')"
					@select="(options) => $emit('select', options)"
					@add="(e) => onAdd('models',e)"
			></ModelListMenu>
			<ModelMenu
					v-if="isActiveItem('models')"
					:meta="activeMenu.item_id"
					@select="(options) => $emit('select', options)"
					@update="(e) => onUpdate('models',e)"
					@delete="(e)=> onDelete('models',e)"
			></ModelMenu>

			<NPCListMenu
				v-if="isActive('npcs')"
				@select="(options) => $emit('select', options)"
				@add="(e) => onAdd('npcs',e)"
			></NPCListMenu>
			<NPCMenu
				v-if="isActiveItem('npcs')"
				:meta="activeMenu.item_id"
				@select="(options) => $emit('select', options)"
				@update="(e) => onUpdate('npcs',e)"
				@delete="(e)=> onDelete('npcs',e)"
			></NPCMenu>

			<!--		Character-->
			<CharacterListMenu
				v-if="isActive('characters')"
				@select="(options) => $emit('select', options)"
				@add="(e) => onAdd('characters',e)"
				@add-default="(e) => onAddDefault(e)"
			></CharacterListMenu>
			<CharacterMenu
					v-if="isActiveItem('characters')"
					:meta="activeMenu.item_id"
					@select="(options) => $emit('select', options)"
					@update="(e) => onUpdate('characters',e)"
					@delete="(e)=> onDelete('characters',e)"
			></CharacterMenu>

			<FileMenu
					v-if="isActiveItem('file')"
					:meta="activeMenu.item_id"
					:manifest="manifest"
					@update="(e) => onUpdate('file',e)"
					@delete="(e)=> onDelete('file',e)"
					@add="(e) => onAdd('file',e)"
			></FileMenu>


			<!--		Actions-->
			<ActionListMenu
					v-if="isActive('action_list')"
					:manifest="manifest"
					@update="(e) => onUpdate('action_list',e)"
					@add="(e) => onAdd('action',e)"
			></ActionListMenu>
			<ActionMenu
					v-if="isActiveItem('action')"
					:meta="activeMenu.item_id"
					@update="(e) => onUpdate('actions',e)"
			></ActionMenu>

			<!--		Post Processing-->
			<PostProcessingMenu
					v-if="isActive('display')"
					:manifest="manifest"
					@update="(e) => onUpdate('display',e)"
			></PostProcessingMenu>

			<VoxelsMenu
					v-if="isActive('voxels')"
					@select="selectVoxel"
					@uploaded_texture="(fileUrl) => $emit('uploaded_texture', fileUrl)"
			/>
		</form>
	</div>
</template>

<script>
import ActionMenu from "./menus/ActionMenu";
import EntityMenu from "./menus/EntityMenu";
import EnvironmentMenu from "./menus/EnvironmentMenu";
import FileMenu from "./menus/FileMenu";
import LightingMenu from "./menus/LightingMenu";
import EntityListMenu from "./menus/EntityListMenu";
import TerrainListMenu from "./menus/TerrainListMenu";
import TerrainMenu from "./menus/TerrainMenu";
import CharacterListMenu from "./menus/CharacterListMenu";
import CharacterMenu from "./menus/CharacterMenu";
import ActionListMenu from "./menus/ActionListMenu";
import LightListMenu from "./menus/LightListMenu";
import MetaverseSettingsMenu from "./menus/MetaverseSettingsMenu";
import PostProcessingMenu from "./menus/PostProcessingMenu";
import ModelListMenu from "./menus/ModelListMenu";
import ModelMenu from "./menus/ModelMenu";
import SoundMenu from "./menus/SoundMenu";
import SoundListMenu from "./menus/SoundListMenu";
import CatalogueListMenu from "./menus/CatalogueListMenu";
import VoxelsMenu from "./menus/VoxelsMenu";
import NPCListMenu from "./menus/NPCListMenu";
import NPCMenu from './menus/NPCMenu'

export default {
	name: "EditorMenu",
	components: {
		CatalogueListMenu,
		PostProcessingMenu,
		MetaverseSettingsMenu,
		FileMenu,
		EnvironmentMenu,
		VoxelsMenu,
		NPCListMenu, NPCMenu,
		SoundListMenu, SoundMenu,
		ModelListMenu, ModelMenu,
		LightListMenu, LightingMenu,
		ActionListMenu, ActionMenu,
		EntityListMenu, EntityMenu,
		CharacterListMenu, CharacterMenu,
		TerrainListMenu, TerrainMenu,
	},
	props: {
		manifest: {
			type: Object,
			required: true
		},
		isSidebar: {
			type: Boolean,
			default: false,
		},
		activeMenu: {
			type: Object,
			default() {
				return {
					menu: "settings",
					item_id: "",
				}
			}
		}
	},
	watch: {
		manifest: {
			handler(newVal, oldVal) {
				this.manifest = newVal
			},
			deep: true
		}
	},
	methods: {
		selectVoxel(voxel_type) {
			this.$emit("selectVoxel", voxel_type)
		},
		isActive: function (key) {
			return this.activeMenu.menu === key && !this.activeMenu.item_id;
		},
		isActiveItem: function (key) {
			return this.activeMenu.menu === key && this.activeMenu.item_id;
		},
		onUpdate(menu, data) {
			this.$emit("update", menu, data);
		},
		onDelete(menu, data) {
			this.$emit("delete", menu, data);
		},
		onAdd(menu, data) {
			this.$emit("add", {menu: menu, data: data});
		},
	}
}
</script>

<style scoped>
</style>
