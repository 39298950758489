<template>
  <div class="voxels-menu mt-4 mb-3">
    <div>
      <div
        class="collapser"
        @click="expanded = !expanded"
      >
        <p>-</p>
      </div>
      <h4 class="mb-3" @click="expanded = !expanded">Custom Voxels</h4>
      <div v-show="expanded">
        <div v-if="$store.state.metaverse.manifest.textures.length === 0">
          <span>No custom voxels uploaded.</span>
        </div>
        <div v-else class="custom-voxels-area">
          <div
              v-for="(voxel, index) in $store.state.metaverse.manifest.textures" :key="`custom-voxel-${index}`"
              @click.stop="onVoxelClick(parseInt(index))"
              class="voxel_selection"
              :style="{'border-color': parseInt(index) === selectedVoxel ? 'green' : 'darkgrey'}"
          >
            <img :src="voxel" style="width: 40px; height: 40px;" alt="alt">
          </div>
        </div>

        <hr />

        <ImageUploader
          class="pb-3 mt-3"
          endpoint="/auth/users/files"
          @uploaded="handleUploadedFile"
        />
      </div>
    </div>

    <div style="color: black; position: fixed; bottom: 0; left: 50%; transform: translateX(-50%); display: flex;">
      <div
          v-for="voxel in voxels" :key="voxel.name"
          @click.stop="onVoxelClick(voxel.type)"
          class="voxel_selection"
          :style="{'border-color': voxel.type === selectedVoxel ? 'green' : 'darkgrey'}"
      >
        <img :src="voxel.src" style="width: 40px; height: 40px;" alt="alt">
      </div>
    </div>

    <div>
      <img class="target" src="/assets/voxel_builder/voxels_target.svg" />
    </div>
  </div>
</template>

<script>

import { VoxelTypes } from '../../../js/instances/controller/voxel_builder/voxel_types'
import ImageUploader from "../../upload/ImageUploader"

export default {
  name: 'VoxelsMenu',
  components: {
    ImageUploader
  },
  data() {
    return {
      data: '',
      selectedVoxel: VoxelTypes.GRASS,
      voxels: [
        {
          name: 'grass',
          type: VoxelTypes.GRASS,
          src: '/assets/voxel_builder/grass.png'
        },
        {
          name: 'farmland',
          type: VoxelTypes.DIRT,
          src: '/assets/voxel_builder/farmland.png'
        },
        {
          name: 'iron',
          type: VoxelTypes.IRON,
          src: '/assets/voxel_builder/iron.png'
        },
        {
          name: 'redstone',
          type: VoxelTypes.REDSTONE,
          src: '/assets/voxel_builder/redstone.png'
        },
        {
          name: 'nether-brick',
          type: VoxelTypes.NETHER_BRICK,
          src: '/assets/voxel_builder/nether-brick.png'
        },
        {
          name: 'red-sandstone',
          type: VoxelTypes.RED_SANDSTONE,
          src: '/assets/voxel_builder/red-sandstone.png'
        },
        {
          name: 'water',
          type: VoxelTypes.WATER,
          src: '/assets/voxel_builder/water.png'
        },
        {
          name: 'lava',
          type: VoxelTypes.LAVA,
          src: '/assets/voxel_builder/lava.gif'
        },
        {
          name: 'snow',
          type: VoxelTypes.SNOW,
          src: '/assets/voxel_builder/snow.png'
        }
      ],
      expanded: true,
    };
  },
  methods: {
    onVoxelClick(type) {
      this.selectedVoxel = type
      this.$emit('select', type)
    },
    handleUploadedFile(fileUrl) {
      this.$emit('uploaded_texture', fileUrl)
    },
    listeners(e) {
      const n = +e.key //Ensure its a number
      if (!n) return

      this.onVoxelClick(n - 1)
    }
  },
  mounted() {
		window.addEventListener('keypress', this.listeners)
  },
  destroyed() {
		window.removeEventListener('keypress', this.listeners)
  }
}
</script>

<style lang="scss">
.custom-voxels-area {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  row-gap: 20px;
}

.collapser {
  position: absolute;
  right: 15px;
  border-radius: 3px;
  border: 1px solid grey;
  padding: 0px 7px;

  & p {
    font-size: 50px;
    margin: 0 0 4px 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.voxel_selection {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid darkgray;
  background-color: rgba(0, 0, 0, 0.5);
}

.voxel_selection:hover {
  cursor: pointer;
}

.target {
  position: fixed;
  height: 15px;
  width: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
</style>
